var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { keydown: _vm.onCellKeyDown } },
    [
      _vm.cellDetails
        ? _c(
            "v-dialog",
            {
              attrs: { scrollable: "", "max-width": "700" },
              model: {
                value: _vm.showDialog,
                callback: function ($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(
                        _vm._s(_vm.translateName(_vm.cellDetails.entity)) +
                          " = " +
                          _vm._s(_vm.cellDetails.displayValue)
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.cellDetails.entity.formula
                        ? _c("div", [
                            _c("h3", [_vm._v("Computation")]),
                            _c("div", { staticClass: "computation" }, [
                              _c("span", {
                                staticClass: "formula",
                                staticStyle: {
                                  "background-color": "rgb(255 251 124)",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.cellDetails.entity.formula
                                  ),
                                },
                              }),
                              _vm._v(" = "),
                              _c("span", {
                                staticClass: "formula",
                                staticStyle: {
                                  "background-color": "rgb(170 247 255)",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.cellDetails.namesFormula
                                  ),
                                },
                              }),
                              _vm._v(" = "),
                              _c("span", {
                                staticClass: "formula",
                                staticStyle: {
                                  "background-color": "rgb(254 193 255)",
                                },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.cellDetails.valuesFormula
                                  ),
                                },
                              }),
                              _vm._v(" = "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.cellDetails.computedValue)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.cellDetails.dependencies
                        ? _c("div", [
                            _c("h3", [_vm._v("Cells that depend of this one")]),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.cellDetails.dependencies,
                                function (cell, k) {
                                  return _c("li", { key: cell.key + "-" + k }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.translateName(cell.entity))
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("span", {
                                      staticClass: "formula",
                                      staticStyle: {
                                        "background-color": "rgb(170 247 255)",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(cell.namesFormula),
                                      },
                                    }),
                                    _vm._v(" = "),
                                    _c(
                                      "span",
                                      { attrs: { title: cell.computedValue } },
                                      [_vm._v(_vm._s(cell.displayValue))]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { "x-small": "", link: "", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.debugDetails = !_vm.debugDetails
                            },
                          },
                        },
                        [_vm._v("debug")]
                      ),
                      _vm.debugDetails
                        ? _c("pre", [_vm._v(_vm._s(_vm.cellDetails))])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.addDialog,
            callback: function ($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog",
          },
        },
        [
          _vm.addDialog
            ? _c("add-branch", {
                attrs: { branches: _vm.branchesToAdd, branch: _vm.addType },
                on: {
                  close: function ($event) {
                    _vm.addDialog = false
                  },
                  save: _vm.onAdd,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.editedEntity
            ? _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v("User Profile"),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _c("Field", {
                            attrs: { label: "Code" },
                            model: {
                              value: _vm.editedEntity.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedEntity, "code", $$v)
                              },
                              expression: "editedEntity.code",
                            },
                          }),
                          _c("Field", {
                            attrs: { label: "Nom" },
                            model: {
                              value: _vm.editedEntity.name_fr,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedEntity, "name_fr", $$v)
                              },
                              expression: "editedEntity.name_fr",
                            },
                          }),
                          _c("Field", {
                            attrs: { label: "Name" },
                            model: {
                              value: _vm.editedEntity.name_en,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedEntity, "name_en", $$v)
                              },
                              expression: "editedEntity.name_en",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("small", [_vm._v("*indicates required field")]),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                              _vm.editedEntity = null
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.saveName()
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    centered: "",
                    color: "purple",
                    "align-with-title": "",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tabs-slider", { attrs: { color: "purple" } }),
                  _c("v-tab", [_vm._v("EF")]),
                  _c("v-tab", [_vm._v("Pre-MSI")]),
                  _c("v-tab", [_vm._v("Analyse-MSI")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-btn",
                { attrs: { color: "success" }, on: { click: _vm.getJson } },
                [_vm._v("save")]
              ),
              _vm.canAddYear
                ? _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.addYear()
                        },
                      },
                    },
                    [_vm._v("Add Year")]
                  )
                : _vm._e(),
              _vm.addedYears.length
                ? _c(
                    "v-btn",
                    {
                      attrs: { "x-small": "", color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.removeYear()
                        },
                      },
                    },
                    [_vm._v("Remove Added Year")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", color: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.exportData()
                    },
                  },
                },
                [_vm._v("Export")]
              ),
              _c("v-checkbox", {
                attrs: { label: "Years Up", dense: "" },
                model: {
                  value: _vm.yearsOrderUp,
                  callback: function ($$v) {
                    _vm.yearsOrderUp = $$v
                  },
                  expression: "yearsOrderUp",
                },
              }),
              _c("v-range-slider", {
                attrs: {
                  ticks: "",
                  "hide-details": "",
                  min: 0,
                  max: _vm.maxYear - _vm.minYear,
                },
                model: {
                  value: _vm.yearsRange,
                  callback: function ($$v) {
                    _vm.yearsRange = $$v
                  },
                  expression: "yearsRange",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tab === 0,
              expression: "tab === 0",
            },
          ],
          attrs: { flat: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "div",
                { staticClass: "tr" },
                [
                  _c("expanders", {
                    attrs: { levels: 3 },
                    on: {
                      click: function ($event) {
                        return _vm.expand("financialStatements", $event)
                      },
                    },
                  }),
                  _vm._l(_vm.yearsCols, function (y) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isYearVisible(y),
                            expression: "isYearVisible(y)",
                          },
                        ],
                        key: y,
                        staticClass: "td data",
                      },
                      [_vm._v(_vm._s(y))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "draggable",
                {
                  attrs: {
                    group: "fs",
                    handle: ".handle",
                    disabled: !_vm.config,
                  },
                  model: {
                    value: _vm.data.financialStatements,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "financialStatements", $$v)
                    },
                    expression: "data.financialStatements",
                  },
                },
                _vm._l(_vm.data.financialStatements, function (fs, fsIdx) {
                  return _c("div", { key: "fs" + fs.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr",
                        class: _vm.trcls(fs, fsIdx),
                        on: {
                          click: function ($event) {
                            return _vm.toggleExpand(fs)
                          },
                        },
                      },
                      [
                        _vm.config
                          ? [
                              _c(
                                "v-icon",
                                { staticClass: "handle", attrs: { small: "" } },
                                [_vm._v("mdi-menu")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "editBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editName(fs)
                                    },
                                  },
                                },
                                [_vm._v("fa-pen")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "delBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteEntity(
                                        _vm.data.financialStatements,
                                        fsIdx
                                      )
                                    },
                                  },
                                },
                                [_vm._v("fa-trash")]
                              ),
                            ]
                          : _vm._e(),
                        _c("div", { staticClass: "th code" }, [
                          _vm._v(_vm._s(fs.code)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "th name" },
                          [
                            _c("expanded", { attrs: { row: fs } }),
                            _vm._v(_vm._s(_vm.translateName(fs))),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: fs._expand,
                            expression: "fs._expand",
                          },
                        ],
                      },
                      [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              group: "fh",
                              handle: ".handle",
                              disabled: !_vm.config,
                            },
                            model: {
                              value: fs.children,
                              callback: function ($$v) {
                                _vm.$set(fs, "children", $$v)
                              },
                              expression: "fs.children",
                            },
                          },
                          _vm._l(fs.children, function (fh, fhIdx) {
                            return _c("div", { key: "fh" + fh.id }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tr",
                                  class: _vm.trcls(fh, fhIdx),
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleExpand(fh)
                                    },
                                  },
                                },
                                [
                                  _vm.config
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "handle",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-menu")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "editBr",
                                            attrs: { "x-small": "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.editName(fh)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-pen")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "delBr",
                                            attrs: { "x-small": "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteEntity(
                                                  fs.children,
                                                  fhIdx
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("fa-trash")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c("div", { staticClass: "th code" }, [
                                    _vm._v(_vm._s(fh.code)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "th name" },
                                    [
                                      _c("expanded", { attrs: { row: fh } }),
                                      _vm._v(_vm._s(_vm.translateName(fh))),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.yearsCols, function (y) {
                                    return _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isYearVisible(y),
                                            expression: "isYearVisible(y)",
                                          },
                                        ],
                                        key: y,
                                        staticClass: "th data",
                                      },
                                      [_vm._v(_vm._s(y))]
                                    )
                                  }),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: fh._expand,
                                      expression: "fh._expand",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        group: "fa",
                                        handle: ".handle",
                                        disabled: !_vm.config,
                                      },
                                      model: {
                                        value: fh.children,
                                        callback: function ($$v) {
                                          _vm.$set(fh, "children", $$v)
                                        },
                                        expression: "fh.children",
                                      },
                                    },
                                    _vm._l(fh.children, function (fa, faIdx) {
                                      return _c("div", { key: "fa" + fa.id }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "tr",
                                            class: _vm.trcls(fa, faIdx),
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleExpand(fa)
                                              },
                                            },
                                          },
                                          [
                                            _vm.config
                                              ? [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "handle",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-menu")]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "editBr",
                                                      attrs: { "x-small": "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.editName(
                                                            fa
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("fa-pen")]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "delBr",
                                                      attrs: { "x-small": "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteEntity(
                                                            fh.children,
                                                            faIdx
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("fa-trash")]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "th code" },
                                              [_vm._v(_vm._s(fa.code))]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "td name" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.translateName(fa))
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.yearsCols,
                                              function (year) {
                                                return _c("div", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.isYearVisible(year),
                                                      expression:
                                                        "isYearVisible(year)",
                                                    },
                                                  ],
                                                  key: year,
                                                  staticClass: "td data",
                                                  attrs: {
                                                    id:
                                                      "fsa:" +
                                                      fa.id +
                                                      ":" +
                                                      year,
                                                    tabindex: "1",
                                                  },
                                                  on: {
                                                    dblclick: function (
                                                      $event
                                                    ) {
                                                      $event.preventDefault()
                                                      return _vm.showCellDetails(
                                                        "fsa:" +
                                                          fa.id +
                                                          ":" +
                                                          year
                                                      )
                                                    },
                                                    blur: _vm.onBlur,
                                                    focus: function ($event) {
                                                      return _vm.selectCell(
                                                        "fsa:" +
                                                          fa.id +
                                                          ":" +
                                                          year
                                                      )
                                                    },
                                                  },
                                                })
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ])
                                    }),
                                    0
                                  ),
                                  _vm.config
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tr financialAccount addBr",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.add(
                                                "financialAccounts",
                                                fh
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Add Financial Account ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                        _vm.config
                          ? _c(
                              "div",
                              {
                                staticClass: "tr financialHeading addBr",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.add("financialHeadings", fs)
                                  },
                                },
                              },
                              [_vm._v(" Add Financial Heading ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
              _vm.config
                ? _c(
                    "div",
                    {
                      staticClass: "tr financialStatement addBr",
                      on: {
                        click: function ($event) {
                          return _vm.add("financialStatements")
                        },
                      },
                    },
                    [_vm._v(" Add Financial Statement ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tab === 1,
              expression: "tab === 1",
            },
          ],
          attrs: { flat: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "div",
                { staticClass: "tr" },
                [
                  _c("expanders", {
                    attrs: { levels: "2" },
                    on: {
                      click: function ($event) {
                        return _vm.expand("aggregateHeadings", $event)
                      },
                    },
                  }),
                  _vm._l(_vm.yearsCols, function (year) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isYearVisible(year),
                            expression: "isYearVisible(year)",
                          },
                        ],
                        key: year,
                        staticClass: "td data",
                      },
                      [_vm._v(_vm._s(year))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "draggable",
                {
                  attrs: {
                    group: "agh",
                    handle: ".handle",
                    disabled: !_vm.config,
                  },
                  model: {
                    value: _vm.data.aggregateHeadings,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "aggregateHeadings", $$v)
                    },
                    expression: "data.aggregateHeadings",
                  },
                },
                _vm._l(_vm.data.aggregateHeadings, function (ah, ahIdx) {
                  return _c("div", { key: "ah" + ah.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr",
                        class: _vm.trcls(ah, ahIdx),
                        on: {
                          click: function ($event) {
                            return _vm.toggleExpand(ah)
                          },
                        },
                      },
                      [
                        _vm.config
                          ? [
                              _c(
                                "v-icon",
                                { staticClass: "handle", attrs: { small: "" } },
                                [_vm._v("mdi-menu")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "editBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editName(ah)
                                    },
                                  },
                                },
                                [_vm._v("fa-pen")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "delBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteEntity(
                                        _vm.data.aggregateHeadings,
                                        ahIdx
                                      )
                                    },
                                  },
                                },
                                [_vm._v("fa-trash")]
                              ),
                            ]
                          : _vm._e(),
                        _c("div", { staticClass: "th code" }, [
                          _vm._v(_vm._s(ah.code)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "th name" },
                          [
                            _c("expanded", { attrs: { row: ah } }),
                            _vm._v(_vm._s(_vm.translateName(ah))),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: ah._expand,
                            expression: "ah._expand",
                          },
                        ],
                      },
                      [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              group: "agg",
                              handle: ".handle",
                              disabled: !_vm.config,
                            },
                            model: {
                              value: ah.children,
                              callback: function ($$v) {
                                _vm.$set(ah, "children", $$v)
                              },
                              expression: "ah.children",
                            },
                          },
                          _vm._l(ah.children, function (ag, agIdx) {
                            return _c(
                              "div",
                              {
                                key: "ag" + ag.id,
                                staticClass: "tr",
                                class: _vm.trcls(ag, agIdx),
                              },
                              [
                                _vm.config
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "handle",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("mdi-menu")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "editBr",
                                          attrs: { "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editName(ag)
                                            },
                                          },
                                        },
                                        [_vm._v("fa-pen")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "delBr",
                                          attrs: { "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deleteEntity(
                                                ah.children,
                                                agIdx
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("fa-trash")]
                                      ),
                                    ]
                                  : _vm._e(),
                                _c("div", { staticClass: "th code" }, [
                                  _vm._v(_vm._s(ag.code)),
                                ]),
                                _c("div", { staticClass: "th name" }, [
                                  _vm._v(_vm._s(_vm.translateName(ag))),
                                ]),
                                _vm.config
                                  ? _c(
                                      "div",
                                      { staticClass: "td formula" },
                                      [
                                        _vm.editedFormula === ag
                                          ? _c("FormulaEditor", {
                                              attrs: {
                                                config: _vm.formulaEditorConfig,
                                              },
                                              on: {
                                                input: _vm.initComputer,
                                                close: function ($event) {
                                                  _vm.editedFormula = null
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.editedFormula.formula,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedFormula,
                                                    "formula",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedFormula.formula",
                                              },
                                            })
                                          : _c("span", {
                                              staticClass: "formula",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formula(ag.formula)
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editedFormula = ag
                                                },
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.yearsCols, function (year) {
                                  return _c("div", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isYearVisible(year),
                                        expression: "isYearVisible(year)",
                                      },
                                    ],
                                    key: year,
                                    staticClass: "td data",
                                    class: { forced: _vm.data.forced },
                                    attrs: {
                                      id: "agg:" + ag.id + ":" + year,
                                      tabindex: "1",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.selectCell(
                                          "agg:" + ag.id + ":" + year
                                        )
                                      },
                                      blur: _vm.onBlur,
                                      dblclick: function ($event) {
                                        $event.preventDefault()
                                        return _vm.showCellDetails(
                                          "agg:" + ag.id + ":" + year
                                        )
                                      },
                                    },
                                  })
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                        _vm.config
                          ? _c(
                              "div",
                              {
                                staticClass: "tr aggregate addBr",
                                on: {
                                  click: function ($event) {
                                    return _vm.add("aggregates", ah)
                                  },
                                },
                              },
                              [_vm._v(" Add Aggregate ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
              _vm.config
                ? _c(
                    "div",
                    {
                      staticClass: "tr aggregateHeading addBr",
                      on: {
                        click: function ($event) {
                          return _vm.add("aggregateHeadings")
                        },
                      },
                    },
                    [_vm._v(" Add Aggregate Heading ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tab === 2,
              expression: "tab === 2",
            },
          ],
          class: _vm.instView,
          attrs: { flat: "" },
        },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c(
                "div",
                { staticClass: "tr" },
                [
                  _c(
                    "div",
                    { staticClass: "th", staticStyle: { flex: "1" } },
                    [
                      _c("expanders", {
                        staticClass: "mr-5",
                        attrs: { levels: 3 },
                        on: {
                          click: function ($event) {
                            return _vm.expand("axes", $event)
                          },
                        },
                      }),
                      _c(
                        "v-btn-toggle",
                        {
                          model: {
                            value: _vm.instView,
                            callback: function ($$v) {
                              _vm.instView = $$v
                            },
                            expression: "instView",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { "x-small": "", value: "viewValue" } },
                            [_vm._v("Values")]
                          ),
                          _c(
                            "v-btn",
                            { attrs: { "x-small": "", value: "viewScore" } },
                            [_vm._v("Scores")]
                          ),
                          _c(
                            "v-btn",
                            { attrs: { "x-small": "", value: "viewEval" } },
                            [_vm._v("Evals")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._l(_vm.yearsCols, function (year) {
                    return _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isYearVisible(year),
                            expression: "isYearVisible(year)",
                          },
                        ],
                        key: year,
                        staticClass: "td data",
                      },
                      [_vm._v(_vm._s(year))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "draggable",
                {
                  attrs: {
                    group: "axe",
                    handle: ".handle",
                    disabled: !_vm.config,
                  },
                  model: {
                    value: _vm.data.axes,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "axes", $$v)
                    },
                    expression: "data.axes",
                  },
                },
                _vm._l(_vm.data.axes, function (axe, axeIdx) {
                  return _c("div", { key: "axe" + axe.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "tr",
                        class: _vm.trcls(axe, axeIdx),
                        on: {
                          click: function ($event) {
                            return _vm.toggleExpand(axe)
                          },
                        },
                      },
                      [
                        _vm.config
                          ? [
                              _c(
                                "v-icon",
                                { staticClass: "handle", attrs: { small: "" } },
                                [_vm._v("mdi-menu")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "editBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editName(axe)
                                    },
                                  },
                                },
                                [_vm._v("fa-pen")]
                              ),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "delBr",
                                  attrs: { "x-small": "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteEntity(
                                        _vm.data.axes,
                                        axeIdx
                                      )
                                    },
                                  },
                                },
                                [_vm._v("fa-trash")]
                              ),
                            ]
                          : _vm._e(),
                        _c("div", { staticClass: "th code" }, [
                          _vm._v(_vm._s(axe.code)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "th name" },
                          [
                            _c("expanded", { attrs: { row: axe } }),
                            _vm._v(_vm._s(_vm.translateName(axe))),
                          ],
                          1
                        ),
                        _vm._l(_vm.yearsCols, function (year) {
                          return _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isYearVisible(year),
                                expression: "isYearVisible(year)",
                              },
                            ],
                            key: year,
                            staticClass: "td data",
                            attrs: { id: "axe:" + axe.id + ":" + year },
                          })
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: axe._expand,
                            expression: "axe._expand",
                          },
                        ],
                      },
                      [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              group: "theme",
                              handle: ".handle",
                              disabled: !_vm.config,
                            },
                            model: {
                              value: axe.children,
                              callback: function ($$v) {
                                _vm.$set(axe, "children", $$v)
                              },
                              expression: "axe.children",
                            },
                          },
                          _vm._l(axe.children, function (theme, themeIdx) {
                            return _c("div", { key: theme.id }, [
                              _c(
                                "div",
                                {
                                  staticClass: "tr",
                                  class: _vm.trcls(theme, themeIdx),
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleExpand(theme)
                                    },
                                  },
                                },
                                [
                                  _vm.config
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "handle",
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-menu")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "editBr",
                                            attrs: { "x-small": "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.editName(theme)
                                              },
                                            },
                                          },
                                          [_vm._v("fa-pen")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "delBr",
                                            attrs: { "x-small": "" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteEntity(
                                                  axe.children,
                                                  themeIdx
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("fa-trash")]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c("div", { staticClass: "th code" }, [
                                    _vm._v(_vm._s(theme.code)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "th name" },
                                    [
                                      _c("expanded", { attrs: { row: theme } }),
                                      _vm._v(_vm._s(_vm.translateName(theme))),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.yearsCols, function (y) {
                                    return _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isYearVisible(y),
                                          expression: "isYearVisible(y)",
                                        },
                                      ],
                                      key: y,
                                      staticClass: "td data",
                                      attrs: {
                                        id: "theme:" + theme.id + ":" + y,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: theme._expand,
                                      expression: "theme._expand",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        group: "inst",
                                        handle: ".handle",
                                        disabled: !_vm.config,
                                      },
                                      model: {
                                        value: theme.children,
                                        callback: function ($$v) {
                                          _vm.$set(theme, "children", $$v)
                                        },
                                        expression: "theme.children",
                                      },
                                    },
                                    _vm._l(
                                      theme.children,
                                      function (inst, instIdx) {
                                        return _c("div", { key: inst.id }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "tr",
                                              class: _vm.trcls(inst, instIdx),
                                            },
                                            [
                                              _vm.config
                                                ? [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "handle",
                                                        attrs: { small: "" },
                                                      },
                                                      [_vm._v("mdi-menu")]
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "editBr",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.editName(
                                                              inst
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("fa-pen")]
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "delBr",
                                                        attrs: {
                                                          "x-small": "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.deleteEntity(
                                                              theme.children,
                                                              instIdx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("fa-trash")]
                                                    ),
                                                  ]
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "th code" },
                                                [_vm._v(_vm._s(inst.code))]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "td name" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.translateName(inst)
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.config
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "td formula",
                                                    },
                                                    [
                                                      _vm.editedFormula === inst
                                                        ? _c("FormulaEditor", {
                                                            attrs: {
                                                              config:
                                                                _vm.formulaEditorConfig,
                                                            },
                                                            on: {
                                                              close: function (
                                                                $event
                                                              ) {
                                                                _vm.editedFormula =
                                                                  null
                                                              },
                                                              input:
                                                                _vm.initComputer,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .editedFormula
                                                                  .formula,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.editedFormula,
                                                                    "formula",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "editedFormula.formula",
                                                            },
                                                          })
                                                        : _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.formula(
                                                                  inst.formula
                                                                )
                                                              ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.editedFormula =
                                                                  inst
                                                              },
                                                            },
                                                          }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "th comp" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: inst.ref_comp,
                                                          expression:
                                                            "inst.ref_comp",
                                                        },
                                                      ],
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              inst,
                                                              "ref_comp",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.updateInstCells(
                                                              inst
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: { value: "<" },
                                                        },
                                                        [_vm._v("<")]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: { value: ">" },
                                                        },
                                                        [_vm._v(">")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "th ref" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: inst.ref_val,
                                                        expression:
                                                          "inst.ref_val",
                                                      },
                                                    ],
                                                    domProps: {
                                                      value: inst.ref_val,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.updateInstCells(
                                                          inst
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          inst,
                                                          "ref_val",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "th coef" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: inst.coef,
                                                        expression: "inst.coef",
                                                      },
                                                    ],
                                                    domProps: {
                                                      value: inst.coef,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.updateInstCells(
                                                          inst
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          inst,
                                                          "coef",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.yearsCols,
                                                function (year) {
                                                  return _c("div", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.isYearVisible(
                                                            year
                                                          ),
                                                        expression:
                                                          "isYearVisible(year)",
                                                      },
                                                    ],
                                                    key: year,
                                                    staticClass: "td data",
                                                    attrs: {
                                                      id:
                                                        "inst:" +
                                                        inst.id +
                                                        ":" +
                                                        year,
                                                      tabindex: "1",
                                                    },
                                                    on: {
                                                      focus: function ($event) {
                                                        return _vm.selectCell(
                                                          "inst:" +
                                                            inst.id +
                                                            ":" +
                                                            year
                                                        )
                                                      },
                                                      blur: _vm.onBlur,
                                                      dblclick: function (
                                                        $event
                                                      ) {
                                                        $event.stopPropagation()
                                                        return _vm.showCellDetails(
                                                          "inst:" +
                                                            inst.id +
                                                            ":" +
                                                            year
                                                        )
                                                      },
                                                    },
                                                  })
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm.config
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "tr instrument addBr",
                                          on: {
                                            click: function ($event) {
                                              return _vm.add(
                                                "instruments",
                                                theme
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Add Instrument ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                        _vm.config
                          ? _c(
                              "div",
                              {
                                staticClass: "tr theme addBr",
                                on: {
                                  click: function ($event) {
                                    return _vm.add("themes", axe)
                                  },
                                },
                              },
                              [_vm._v(" Add Theme ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
              _vm.config
                ? _c(
                    "div",
                    {
                      staticClass: "tr axe addBr",
                      on: {
                        click: function ($event) {
                          return _vm.add("axes")
                        },
                      },
                    },
                    [_vm._v(" Add Axe ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }